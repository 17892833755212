<template>
  <div>
    <base-header class="pb-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Parâmetros</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <form class="needs-validation">
        <card>
          <h3 slot="header" class="mb-0">
            <i class="far fa-envelope mr-2"></i> Mensagens SMS
          </h3>

          <template>
            <tabs class="my-tabs">
              <template slot="boleto">
                <label @click="carregaDados()">Boleto</label>
              </template>
              <template slot="ccredito">
                <label @click="carregaDados()">Cartão de Crédito</label>
              </template>
              <template slot="outros">
                <label @click="carregaDados()">Outros</label>
              </template>
              <!-- Aba BOLETO -->
              <tab title-slot="boleto">
                <div class="row">
                  <div class="col-3">
                    <label class="form-control-label">Tipo envio</label>
                    <div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="sms.due_today"
                          class="custom-control-input"
                          id="check1"
                        />
                        <label class="custom-control-label" for="check1"
                          >Vence no dia</label
                        >
                      </div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="sms.three_days_late"
                          class="custom-control-input"
                          id="check2"
                        />
                        <label
                          class="custom-control-label"
                          for="check2"
                          ><strong>3</strong> dias de atraso</label
                        >
                      </div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="sms.eigth_days_late"
                          class="custom-control-input"
                          id="check3"
                        />
                        <label
                          class="custom-control-label"
                          for="check3"
                          ><strong>8</strong> dias de atraso</label
                        >
                      </div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="sms.thirteen_days_late"
                          class="custom-control-input"
                          id="check4"
                        />
                        <label
                          class="custom-control-label"
                          for="check4"
                          ><strong>13</strong> dias de atraso</label
                        >
                      </div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="sms.eighteen_days_late"
                          class="custom-control-input"
                          id="check5"
                        />
                        <label
                          class="custom-control-label"
                          for="check5"
                          ><strong>18</strong> dias de atraso</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="form-control-label">Limite diário</label>
                    <div class="custom-switch ml-2">
                      <input
                        type="checkbox"
                        v-model="sms.unlimited_message"
                        class="custom-control-input"
                        id="unlimited_message"
                      />
                      <label
                        class="custom-control-label"
                        for="unlimited_message"
                        >Sem limite</label
                      >
                    </div>
                    <base-input
                      v-if="sms.unlimited_message == false"
                      class="mt-3"
                      name="limite"
                      v-mask="['####']"
                      placeholder="Quantidade limite de SMS"
                      v-model="sms.message_amount"
                    ></base-input>
                  </div>
                  <div class="col-6">
                    <label class="form-control-label">Mensagem</label>

                    <table style="width:100%">
                      <tr>
                        <td class="text-right p-2">
                          <base-button
                            size="sm"
                            type="secondary"
                            @click="compoeMsg('{primeiro_nome}', sms)"
                            >Nome</base-button
                          >
                          <base-button
                            size="sm"
                            type="secondary"
                             @click="compoeMsg('{link_boleto_pagamento}',sms)"
                            >Link Boleto</base-button
                          >
                          <base-button
                            size="sm"
                            type="secondary"
                            @click="compoeMsg('{d}',sms)"
                            >Qtde dias</base-button
                          >

                          <base-button
                            size="sm"
                            type="secondary"
                            @click="compoeMsg('{valor}',sms)"
                            >Valor</base-button
                          >

                        </td>
                      </tr>
                    </table>

                    <textarea
                      rows="4"
                      id="msgTicket"
                      maxlength="150"
                      @input="restaCarac(sms)"
                      v-model="sms.message"
                      style="width:100%;"
                      class="form-control"
                      placeholder="Mensagem do SMS"
                    ></textarea>

                    <small class="label-restam-caracteres"
                      ><b>{{ sms.restante }}</b
                      >/{{ sms.permitido }} restantes {{ sms.obsAcento }}</small
                    >
                  </div>
                </div>
                <div class="text-center mt-4">
                  <base-button type="primary" @click="saveParams(sms)"
                    ><i class="far fa-envelope mr-2"></i>Salvar</base-button
                  >
                </div>
              </tab>
              <!-- Aba CARTAO DE CREDITO -->
              <tab title-slot="ccredito">
                <div class="row">
                  <div class="col-3">
                    <label class="form-control-label"
                      >Tipo envio</label
                    >
                    <div>
                      
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="credit.three_days_late"
                          class="custom-control-input"
                          id="check6"
                        />
                        <label
                          class="custom-control-label"
                          for="check6"
                          ><strong>3</strong> dias de atraso</label
                        >
                      </div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="credit.eigth_days_late"
                          class="custom-control-input"
                          id="check7"
                        />
                        <label
                          class="custom-control-label"
                          for="check7"
                          ><strong>8</strong> dias de atraso</label
                        >
                      </div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="credit.thirteen_days_late"
                          class="custom-control-input"
                          id="check8"
                        />
                        <label
                          class="custom-control-label"
                          for="check8"
                          ><strong>13</strong> dias de atraso</label
                        >
                      </div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="credit.eighteen_days_late"
                          class="custom-control-input"
                          id="check9"
                        />
                        <label
                          class="custom-control-label"
                          for="check9"
                          ><strong>18</strong> dias de atraso</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="form-control-label"
                      >Limite diário</label
                    >
                    <div class="custom-switch ml-2">
                      <input
                        type="checkbox"
                        v-model="credit.unlimited_message"
                        class="custom-control-input"
                        id="unlimited_message_credit"
                      />
                      <label
                        class="custom-control-label"
                        for="unlimited_message_credit"
                        >Sem limite</label
                      >
                    </div>
                    <base-input
                      v-if="credit.unlimited_message == false"
                      class="mt-3"
                      name="limite"
                      v-mask="['####']"
                      placeholder="Quantidade limite de SMS"
                      v-model="credit.message_amount"
                    ></base-input>
                  </div>
                  <div class="col-6">
                    <label class="form-control-label"
                      >Mensagem</label
                    >
                    <table style="width:100%">
                      <tr>
                        <td class="text-right p-2">
                          <base-button
                            size="sm"
                            type="secondary"
                            @click="compoeMsg('{primeiro_nome}', credit)"
                            >Nome</base-button
                          >
                          <base-button
                            size="sm"
                            type="secondary"
                            @click="compoeMsg('{d}',credit)"
                            >Qtde dias</base-button
                          >
                          <base-button
                            size="sm"
                            type="secondary"
                            @click="compoeMsg('{valor}',credit)"
                            >Valor</base-button
                          >
                        </td>
                      </tr>
                    </table>
                    <textarea
                      rows="4"
                      id="msgCredit"
                      maxlength="150"
                      @input="restaCarac(credit)"
                      v-model="credit.message"
                      style="width:100%;"
                      class="form-control"
                      placeholder="Mensagem do SMS"
                    ></textarea>
                    <small class="label-restam-caracteres"
                      ><b>{{ credit.restante }}</b
                      >/{{ credit.permitido }} restantes {{ credit.obsAcento }}</small
                    >
                  </div>
                </div>
                <div class="text-center mt-4">
                  <base-button type="primary" @click="saveParams(credit)"
                    ><i class="far fa-envelope mr-2"></i>Salvar</base-button
                  >
                </div>
              </tab>
              <!-- Aba OUTROS-->
              <tab title-slot="outros">
                <div class="row">
                  <div class="col-3">
                    <label class="form-control-label"
                      >Categoria de Envio</label
                    >
                    <div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="other.due_today"
                          class="custom-control-input"
                          id="check10"
                        />
                        <label class="custom-control-label" for="check10"
                          >Boleto</label
                        >
                      </div>
                      <div class="custom-switch ml-2">
                        <input
                          type="checkbox"
                          v-model="other.three_days_late"
                          class="custom-control-input"
                          id="check11"
                        />
                        <label
                          class="custom-control-label"
                          for="check11"
                          >Cartão de Crédito</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="form-control-label"
                      >Limite instantâneo</label
                    >
                    <div class="custom-switch ml-2">
                      <input
                        type="checkbox"
                        v-model="other.unlimited_message"
                        class="custom-control-input"
                        id="unlimited_message_other"
                      />
                      <label
                        class="custom-control-label"
                        for="unlimited_message_other"
                        >Sem limite</label
                      >
                    </div>
                    <base-input
                      v-if="other.unlimited_message == false"
                      class="mt-3"
                      name="limite"
                      v-mask="['####']"
                      placeholder="Quantidade limite de SMS"
                      v-model="other.message_amount"
                    ></base-input>
                  </div>
                  <div class="col-6">
                    <label class="form-control-label"
                      >Mensagem</label
                    >
                    <table style="width:100%">
                      <tr>
                        <td class="text-right p-2">
                          <base-button
                            size="sm"
                            type="secondary"
                            @click="compoeMsg('{primeiro_nome}', other)"
                            >Nome</base-button
                          >
                        </td>
                      </tr>
                    </table>
                    <textarea
                      rows="4"
                      id="msgOther"
                      maxlength="150"
                      @input="restaCarac(other)"
                      v-model="other.message"
                      style="width:100%;"
                      class="form-control"
                      placeholder="Mensagem do SMS"
                    ></textarea>
                    <small class="label-restam-caracteres"
                      ><b>{{ other.restante }}</b
                      >/{{ other.permitido }} restantes {{ other.obsAcento }}</small
                    >
                  </div>
                </div>
                <div class="text-center mt-4">
                  <base-button type="primary" @click="enviar(other)"
                    ><i class="far fa-envelope mr-2"></i>Enviar</base-button
                  >
                </div>
              </tab>
            </tabs>
          </template>
        </card>
      </form>
    </div>
  </div>
</template>

<script>
import { Tabs, Tab } from "vue-slim-tabs";
export default {
  components: {
    Tabs,
    Tab,
  },

  created() {
    this.loadParams();
  },
  data() {
    return {
      sms: {
        due_today: false,
        three_days_late: false,
        eigth_days_late: false,
        thirteen_days_late: false,
        eighteen_days_late: false,
        unlimited_message: false,
        message_amount: "0",
        message: "",
      },

      credit: {
        due_today: false,
        three_days_late: false,
        eigth_days_late: false,
        thirteen_days_late: false,
        eighteen_days_late: false,
        unlimited_message: false,
        message_amount: "0",
        message: "",
      },

      other: {
        due_today: false,
        three_days_late: false,
        unlimited_message: false,
        message_amount: "0",
        message: "",
      },

      modals: {
        modalDenied: false,
      },
      restante: 0,
      permitido: 150,
      obsAcento: "sem acento",
    };
  },
  methods: {

    carregaDados(){
      this.loadParams()
    },

    compoeMsg(param, cmp){
      cmp.message = cmp.message + param
      if(cmp.id == 1){
        document.getElementById("msgTicket").focus() 
      }else if(cmp.id == 2){
        document.getElementById("msgCredit").focus() 
      }else{
        document.getElementById("msgOther").focus() 
      }
     
      this.restaCarac(cmp);
    },

    restaCarac(cmp) {
      //let regex = /\W|_/;
      let regex1 = /[À-ú]/;
      let regex2 = /[´`~^]/;
      cmp.permitido = 150;
      cmp.obsAcento = "sem acento";
      if (regex1.test(cmp.message) || regex2.test(cmp.message)) {
        cmp.permitido = 70;
        cmp.obsAcento = "com acento";
      }
      cmp.restante = cmp.permitido - cmp.message.length;
    },
    loadParams() {
      this.$clubApi
        .get("/params/obter_campos_tela")
        .then((response) => {
          this.sms = response.data.object[0];
          this.credit = response.data.object[1];
          this.other = response.data.object[2];
          this.restaCarac(this.sms);
          this.restaCarac(this.other);
          this.restaCarac(this.credit);
          console.log(response.data.object);
        })
        .catch(() => {
          this.$notify({
            type: "warning",
            message: "Erro ao carregar parametros do sistema",
          });
        })
        .finally(() => {
          NProgress.done();
        });
    },

    saveParams(cmp) {
      try {
        this.$validator.validateAll();
      } finally {
        this.validated = true;
      }
      if (this.validated) {
        if (cmp.restante < 0) {
          this.$notify({
            type: "warning",
            message: "Mensagem com mais caracteres do que o permitido!",
          });
          return false;
        }

         if (cmp.message == "") {
          this.$notify({
            type: "warning",
            message: "Informe a mensagem a ser enviada.",
          });
          return false;
        }

        this.beginSaveProcess = true;
        this.$clubApi
          .post("/params/save",cmp)
          .then(() => {
            this.$notify({
              type: "success",
              message: "Dados salvos com sucesso.",
            });
          })
          .catch((error) => {
            this.$notify({ type: "warning", message: error.response.data.msg });
          })
          .finally(() => {
            this.beginSaveProcess = false;
            NProgress.done();
          });
      }
    },
  enviar(cmp) {
      try {
        this.$validator.validateAll();
      } finally {
        this.validated = true;
      }
      if (this.validated) {
        if (cmp.restante < 0) {
          this.$notify({
            type: "warning",
            message: "Mensagem com mais caracteres do que o permitido!",
          });
          return false;
        }

        if (cmp.unlimited_message == false && cmp.message_amount == "") {
          this.$notify({
            type: "warning",
            message: "Informe a quantidade limite de SMS para envio.",
          });
          return false;
        }

         if (cmp.due_today == false && cmp.three_days_late == false) {
          this.$notify({
            type: "warning",
            message: "Informe ao menos uma categoria de envio.",
          });
          return false;
        }
        
        if (cmp.message == "") {
          this.$notify({
            type: "warning",
            message: "Informe a mensagem a ser enviada.",
          });
          return false;
        }

        this.beginSaveProcess = true;
        this.$clubApi
          .post("/params/send",cmp)
          .then(() => {
            this.$notify({
              type: "success",
              message: "Mensagens enviadas com sucesso.",
            });
          })
          .catch((error) => {
            this.$notify({ type: "warning", message: error.response.data.msg });
          })
          .finally(() => {
            this.beginSaveProcess = false;
            NProgress.done();
          });
      }
    },
    },
};
</script>
<style>
.vue-tablist {
  list-style: none;
  display: flex;
  padding-left: 0;
  border-bottom: 1px solid #e2e2e2;
}

.vue-tab {
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-bottom-color: #e2e2e2;
  border-radius: 3px 3px 0 0;
  background-color: white;
  position: relative;
  bottom: -1px;
  font-size: 13px;
}

.vue-tab[aria-selected="true"] {
  border-color: #e2e2e2;
  border-bottom-color: transparent;
  background-color: #f8f8ff;
}

.vue-tab[aria-disabled="true"] {
  cursor: not-allowed;
  color: #999;
}
</style>
